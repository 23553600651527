export function About() {
  return (
    <main className="container">
      <article>
        <h1>About This Website</h1>
        <hr className="w-100" />
        <h4>Mission</h4>
        <p>
          The primary purpose of this website is to assist both foreigners and
          Ethiopian citizens in China by providing essential information related
          to the Ethiopian Embassy. Whether you're looking for details about
          visa renewals, passport services, or other consular matters, my goal
          is to make this information easily accessible and straightforward.
        </p>
        <h4>Why This Website?</h4>
        <p>
          As a fellow Ethiopian currently studying in Nanjing, China, I
          understand firsthand the challenges of navigating embassy-related
          processes. My own struggles with finding reliable information about
          services like visa and passport renewals inspired me to create this
          website. I wanted to leverage my skills to help others avoid the same
          difficulties I faced.
        </p>
        <p>
          This website is designed for everyone located in China who needs
          information about the Ethiopian Embassy – from Ethiopian citizens and
          Chinese nationals to international students and expatriates. No matter
          who you are, if you require assistance with embassy-related matters,
          this platform is here to help.
        </p>
        <h4>Commitment</h4>
        <p>
          While this is <strong>not the official website of the Ethiopian Embassy</strong> in
          China, I have compiled accurate and up-to-date information to aid in
          your needs. Whenever possible, I provide direct links to the official
          website for your convenience. The official website <a target="_blank" href="https://www.ethiopiaemb.org.cn/index.htm">www.ethiopianembassy.org.cn</a> is the
        </p>
        <h4>No Cost, Just Help</h4>
        <p>
          I am doing this purely out of a desire to help maintain the flow of
          information. This is a voluntary effort, and I do not receive any
          compensation for this work. My motivation is to ensure that everyone
          can access the information they need without unnecessary hassle.
        </p>
        <h4>About Me</h4>
        <p>
          I am an Ethiopian international student living in Nanjing, China, as
          of 2024. My passion for this project stems from my personal
          experiences and a strong desire to give back to the community. Thank
          you for visiting Ethiopian Embassy In China. I hope you find the
          information provided here helpful. If you have any suggestions or need
          further assistance, please do not hesitate to reach out.
        </p>
      </article>
    </main>
  );
}
