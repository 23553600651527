import { useEffect, useState } from "react";
import React from "react";
import { Article } from "../../Components/Article";
import "./read.css";
import { useParams } from "react-router-dom";

export function Investment() {
  const { id } = useParams();

  const article = selectArticle({ id });

  return (
    <div>
      <Article title={article.title} description={article.description} />
    </div>
  );
}

function selectArticle({ id }) {
  const investment = {
    title: "Investment",
    description: [
      "<p>Ethiopia is located in the Horn of Africa and has land area of 1.14 million km2. Its geographic proximity to the Red Sea, the Gulf region and Indian Ocean makes Ethiopia a gate way to Africa. Ethiopia's population is about 91 million, second populous country in Africa after Nigeria. More than half of Ethiopia's population is young and productive force. Ethiopia is a country of ancient civilization (among the oldest in the world) and cradle of humankind: the 3.2 million year old hominid of Lucy, and the 4.4 million year old Ardi. Ethiopia is also the origin of coffee, which is one of its gifts to the world.</p>",
      "<figure><img src='/images/africa_map.gif' alt='...'></img><figcaption>Map of Africa</figcaption></figure>",
      "<p>Ethiopia is currently in its era of renaissance. Politically, the country is very stable and peaceful. Ethiopia is run by a federal system of government with nine regional states in the federation and two chartered cities directly accountable to the Federal Government. Democratic elections are held every five years and the Ethiopian People's Revolutionary Democratic Front (EPRDF) is the ruling party since 1991. </p>",
      "<p>Ethiopia hosts the Headquarters of the African Union, the UN-Economic Commission for Africa and many regional and international organizations. Addis Ababa, the capital city of Ethiopia, is also the political and diplomatic capital of Africa. Ethiopia is playing a vital role for ensuring larger peace and stability in Africa. </p>",
      "<figure ><img  src='/images/AU.png' alt='...'></img><figcaption>African Union Headquarters</figcaption></figure>",
      "<p>The country has been registering an average GDP growth of 10% for the last ten consecutive years. It is among the top 10 fastest growing economies in the world and the 4th largest GDP at purchasing power parity (PPP) in sub-Sahara Africa, after South Africa, Nigeria, and Angola. When we see the structure of the Ethiopian economy: service contributes 45%, agriculture 42%, & industry 13%. The country has a stable macro-economy, disciplined monetary & fiscal management. </p>",
      "<p>Regarding the energy sector, Ethiopia has the potentiality of generating over 45,000 MW of electricity from hydropower, 1350 GW from wind, and 7000 MW from geothermal. Projects are underway to produce 8,000-10,000MW mainly from hydropower in the year 2015. Moreover, by giving priority for infrastructure development, Ethiopia has developed a national railway construction scheme of more than 6000kms. All regional States and cites are connected with asphalt/gravel roads. The country is linked by international highways with neighboring countries (such as Kenya, Djibouti, Eritrea, and Sudan). </p>",
    ],
  };
  const investment_opportunities = {
    title: "Investment Oppotunities",
    description: [
      "<p>Ethiopia has the potential to become globally competitive in large segments of light manufacturing by leveraging its labour cost and comparative advantage in the natural resource industries', World Bank Report (2012). The major investment opportunities include the following areas.<p>",
      "<p><strong>Manufacturing</strong>:- food & beverage industry (sugar, meat, milk, fruits & vegetables, macaroni & spaghetti, starch, corn flex, etc), textiles and garments, leather and leather products; paper and paper products, chemicals, pharmaceutical & drugs, rubber and plastics products, computer, electronic and optical products, electrical products, machinery and equipment, vehicles & trailers, office and household furniture, construction ceramics and sanitary products, jewelries and related articles, sports equipment, games and toys.</p>",
      "<p><strong>Agriculture</strong>:- food crops (wheat, maize, beans, peas, lentils, soybeans, chickpeas, pulses, etc), Oil crops (rapeseed, linseed, groundnuts, sunflower, niger seed and cotton seed) beverage crops (coffee and tea), Cotton, feed stocks for bio-diesel, fruits & Vegetables, sugarcane plantation, rubber tree plantation, animal feed, livestock development, fishing, honey & beeswax, etc.</p>",
      "<p><strong>Mining</strong>: - precious minerals (gold, tantalum, platinum, nickel, potash soda ash, etc.),construction and industrial minerals (marble, granite, limestone, clay, gypsum, gemstone, iron ore, coal, copper, silica, diatomite, etc), oil and natural gas exploration and development.</p>",
      "<p><strong>Metallurgy & Engineering</strong>: - non-metallic mineral products, basic metal and steel products, engineering and architectural works.</p>",
      "<p><strong>Service Industries</strong>: - hotel & tourism (star designated hotel, resort, motel, lodges and restaurant), grade one tour operation, health service (clinical service, diagnostic service, hospital service), education and training (secondary and higher education, technical and vocational training service), project contracting: construction, water well and mineral exploration, rental of construction machinery, ICT, publishing.</p>",
      "<p><strong>Specific Investment Projects</strong>: - sugar industry, hotel, assembling, manufacturing, and development of industrial parks. List of the investment projects, its estimated capacity & capital are as indicated below. For further feasibility study documents and detailed information please contact Mr. Kebede Beyene, Minister Counselor, mobile 15210672147, email: beyene16@live.com and Mr. Huang Zhuo, Promotion officer, mobile: 13601111476, email: ethiopiaemb.philip@gmail.com).</p>",
      "<h3>Investment Projects</h3>",
      `<ol>
        <li>Sugar projects</li>
        <li>Ghion hotel</li>
        <li>Assembling and manufacturing</li>
        <li>Chemicals & Pharmaceuticals</li>
        <li>Textile Industry Projects</li>
        <li>Garment Industry</li>
        <li>Leather Products</li>
        <li>Construction Materials</li>
        <li>Food & Beverage</li>
      </ol>`,
    ],
  };

  switch (id) {
    case "0":
      return investment;
    case "1":
      return investment_opportunities;
    case "2":
      return investment_opportunities;
  }
}
