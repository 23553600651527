import { Article } from "../../Components/Article";

export function Passport() {
  const title = "Passport services";
  const content = [
    `<p>For Ethiopians residing in P. R. China (including Hong Kong, Macao and Taiwan), Singapore, Australia, Malaysia and N. Korea the Embassy of Ethiopia in P. R. China provides all types of services i.e. renewal, replacement, and issuance of passport.<p>`,
    `<p>For Ethiopians residing in Australia the Consulate General Office of Ethiopia in Melbourne provides renewal service.</p>`,
    `<p><strong>Types of passport services and Fees</strong></p>`,
    `<ol>
      <li>Renewal: 60 USD</li>
      <li>Replacement: 60 USD</li>
      </ol>`,
    `<p><strong>Appllication Procedure:</strong></p>`,
    `<ul>
      <li>Provide application letter on the type of service required</li>
      <li>If the request is for new passport or replacement of passport, passport application form needs to be filled out. (Form is available in the Embassy's website www.ethiopiaemb.org.cn)</li>
      <li>If replacement is required due to loss of passport, police confirmation report should be provided.</li>
      <li>All passport service applicants are required to provide residence permit or residence visa indicating that they reside in P. R. China (including Hong Kong and Macao) Singapore or Malaysia.</li>
      <li>Original and copy of your old passport (if applicable)</li>
      <li>Proof of payment for the passport fee</li></ul>`,
    `<p><strong>How to send application and fees from Hong Kong, Macao, Singapore, Malaysia and North Korea</strong></p>`,
    `<ul>
      <li>Passport applicants from the above-mentioned areas can send their passports and applications through courier to our Embassy in P. R. China to the following address;
        <ul>
          <li>Embassy of Ethiopia No. 3 Xiu Shui Nan Jie, Jian Guo Men Wai, Beijing, 100600, P. R. China</li>`,
    `<li>Passport processing fees and return postage fee should be directly transferred to the Embassy's account through Bank Telegraphic Transfer to the following address :`,
    `<ul>
      <li>Bank of China, Head Office</li>
      <li>Swift Code: BKCH CN BJ</li>
      <li>Beneficiary- Ethiopian Embassy</li>
      <li>Account number- 778350027645</li></ul>`,
    `<p><strong><i>Note</i></strong></p>`,
    `<ul>
      <li>Applicants must make sure that all the required Bank Commission should be borne by themselves while transferring the passport-processing fee.</li>
      <li>The copy of the Bank Telegraphic Transfer should be sent together with application letter, passport application form and passport.</li>
      <li>Applicants who choose to send Draft checks are advised to know the process take a longer period of time than the Bank Telegraphic Transfer.</li>
      <li>If ordinary registered mail preferred - return postage is done for free, otherwise return postage fee for:
        <ul>
          <li>Hong Kong and Macao - 22 USD.</li>
          <li>Singapore and Malaysia - 27 USD.</li>
          <li>North Korea - 30USD</li></ul></li>
          </ul>`,
    `<p>The processing time for a new passport is approximately 3-4 weeks. Once your application is approved, you will be notified to collect your passport in person at the Embassy or Consulate General.</p>`,
    `<p>If you are renewing your passport, you will need to follow a similar process. However, you will also need to submit your old passport along with the other required documents.</p>`,
    `<p>It is important to note that the Ethiopian government does not allow dual citizenship. Therefore, if you hold a foreign passport, you must renounce your Ethiopian citizenship before applying for a new passport.</p>`,
  ];
  return <Article title={title} description={content} />;
}