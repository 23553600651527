import { Carousel } from "../../Components/Carousel";
import { Modal } from "../../Components/Modal";
import { v4 as uuidv4 } from "uuid";
import {
  ArticlePreviewCard,
  ArticlePreviewCard2,
} from "../../Components/Article";
import "./home.css";

export function Home() {
  const newsList = [
    {
      title: "Chinese troupes perform in Ethiopia to celebrate Spring Festival",
      link: "http://www.ecns.cn/m/2018/02-06/291728.shtml",
    },
    {
      title: "China-Ethiopia ties example for all of Africa",
      link: "https://www.ethiopiaemb.org.cn/pdf/Latest%20News.pdf",
    },
    {
      title: "speech by H.E. Amb. Tesfaye Yilma on May 28 celeberation",
      link: "https://www.ethiopiaemb.org.cn/pdf/speech_by_H.E._Amb._Tesfaye_Yilma_on_May_28_celeberation.pdf",
    },
    {
      title: "May 28 Celeberated in Beijing(amharic)",
      link: "https://www.ethiopiaemb.org.cn/pdf/May_28_Celeberated_in_Beijing_amharic_.pdf",
    },
    {
      title:
        "Chongqing-Shandong Chamber of Commerce delegation to visit Ethiopia",
      link: "https://www.ethiopiaemb.org.cn/pdf/Chongqing-Shandong_Chamber_of_Commerce_delegation_to_visit_Ethiopia.pdf",
    },
    {
      title:
        "The Ethiopian Embassy in Beijing takes part at the Invest in Africa forum",
      link: "https://www.ethiopiaemb.org.cn/pdf/Ethiopian_Embassy_takes_part_on_the_CEOIC_conference.pdf",
    },
    {
      title: "Ethiopian Tourism Promotion Seminar held in Beijing ",
      link: "https://www.ethiopiaemb.org.cn/pdf/Ethiopian_Tourism_Promotion_seminar_held_in_Beijing.pdf",
    },
    {
      title:
        "Ethiopian Community in Guangzhou, China, renews support to the Grand Ethiopian Renaissance Dam (GERD) ",
      link: "pdf/Ethiopian_Community_in_Guangzhou__China__renews_support_to_GERD.pdf",
    },
    {
      title: "African Livestock Exhibition and Congress 08-10 May 2014",
      link: "pdf/African_Livestock_Exhibition_and_Congress.pdf",
    },
    {
      title:
        "Ethiopia-Hunan(China) Industrial Investment Cooperation Fair conducted ",
      link: "pdf/Hunan_Visit.pdf",
    },
    {
      title: "Industrial park leads way for others",
      link: "pdf/Industrial_park_leads_way_for_others.pdf",
    },
    {
      title:
        "Ethiopian Consul General in Chongqing calls on Chinese entrepreneurs to invest in Ethiopia ",
      link: "pdf/Ethiopian_Consul_General_calls_on_entrepreneurs_to_invest_in_Ethiopia.pdf",
    },
    {
      title:
        "Bishan, Chinese Leather Shoes & Footwear Industry Association to Visit Ethiopia ",
      link: "pdf/Bishan__Chinese_Leather_Shoes___Footwear_Industry_Association_to_Visit_Ethiopia.pdf",
    },
    {
      title: "The INBAR Working Group deliberates on Rules of Procedure",
      link: "pdf/The_INBAR_Working_Group_deliberates_on_Rules_of_Procedure.pdf",
    },
    {
      title:
        "The Ethiopian Community in China supports a school in Yirgalem Town",
      link: "pdf/The_Ethiopian_Community_in_China_supports_students_in_Yirgalem.pdf",
    },
    {
      title: "Ethiopian Airlines to Start Flights to Shanghai",
      link: "pdf/Ethiopian_Airlines_to_Start_Flights_to_Shanghai.pdf",
    },
    {
      title: "Investment from China beneficial to Africa ",
      link: "pdf/Investment_from_China_beneficial_to_Africa.pdf",
    },
  ];

  function slideLeft(e) {
    e.target.parentElement
      .querySelector("ul")
      .scrollBy({ left: -400, behavior: "smooth" });
  }
  function slideRight(e) {
    e.target.parentElement
      .querySelector("ul")
      .scrollBy({ left: +400, behavior: "smooth" });
  }
  return (
    <main>
      <div className="highlights">
        <Carousel />
      </div>
      <div className="home-page">
        <div className="list-of-nav-links">
          <nav>
            <a href="/about" className="btn">
              About site
            </a>
          </nav>
          <nav>
            <button
              type="button"
              className="btn"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Contact Us
            </button>
            <Modal />
          </nav>
        </div>
        <hr />
        <h2>Latest News</h2>
        <hr />
        <div className="slide-wrapper">
          <ul className="items-list" id="scroll-view">
            {newsList.map((news) => {
              return (
                <li key={uuidv4()} className="item">
                  <ArticlePreviewCard title={news.title} link={news.link} />
                  {/* <a href={news.link}>{news.title}</a> */}
                </li>
              );
            })}
          </ul>
          <button id="prevBtn" onClick={slideLeft}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-arrow-left-square-fill"
              viewBox="0 0 16 16"
              style={{ pointerEvents: "none" }}
            >
              <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1" />
            </svg>
          </button>
          <button id="nextBtn" onClick={slideRight}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-arrow-right-square-fill"
              viewBox="0 0 16 16"
              style={{ pointerEvents: "none" }}
            >
              <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1" />
            </svg>
          </button>
        </div>
        <hr />
        <h2>Regional States</h2>
        <hr />
        <div className="slide-wrapper">
          <ul className="items-list" id="scroll-view">
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"Tigray"}
                link={"https://www.ethiopiaemb.org.cn/profile3.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"Afar"}
                link={"https://www.ethiopiaemb.org.cn/profile4.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"Amhara"}
                link={"https://www.ethiopiaemb.org.cn/profile5.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"Oromia"}
                link={"https://www.ethiopiaemb.org.cn/profile6.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"Somali"}
                link={"https://www.ethiopiaemb.org.cn/profile7.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"Benishangul-Gumuz"}
                link={"https://www.ethiopiaemb.org.cn/profile8.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"SNNP"}
                link={"https://www.ethiopiaemb.org.cn/profile10.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"Gambela"}
                link={"https://www.ethiopiaemb.org.cn/profile9.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2
                title={"Harari"}
                link={"https://www.ethiopiaemb.org.cn/profile11.htm"}
              />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
            <li key={uuidv4()} className="item">
              <ArticlePreviewCard2 title={"Addis Ababa"} />
              {/* <a href={news.link}>{news.title}</a> */}
            </li>
          </ul>
          <button id="prevBtn" onClick={slideLeft}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-arrow-left-square-fill"
              viewBox="0 0 16 16"
              style={{ pointerEvents: "none" }}
            >
              <path d="M16 14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2zm-4.5-6.5H5.707l2.147-2.146a.5.5 0 1 0-.708-.708l-3 3a.5.5 0 0 0 0 .708l3 3a.5.5 0 0 0 .708-.708L5.707 8.5H11.5a.5.5 0 0 0 0-1" />
            </svg>
          </button>
          <button id="nextBtn" onClick={slideRight}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="currentColor"
              className="bi bi-arrow-right-square-fill"
              viewBox="0 0 16 16"
              style={{ pointerEvents: "none" }}
            >
              <path d="M0 14a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2zm4.5-6.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5a.5.5 0 0 1 0-1" />
            </svg>
          </button>
        </div>
        <div></div>
        <hr />
        <h2>Major Cities</h2>
        <hr />
        <div className="slide-wrapper">
          <ul className="items-list" id="scroll-view">
            <li className="item">
              <ArticlePreviewCard2
                title={"Addis Ababa"}
                link={"https://www.ethiopiaemb.org.cn/profile1.htm"}
              />
            </li>
            <li className="item">
              <ArticlePreviewCard2
                title={"Dire Dawa"}
                link={"https://www.ethiopiaemb.org.cn/profile2.htm"}
              />
            </li>
          </ul>
        </div>
        <div></div>
      </div>
    </main>
  );
}
