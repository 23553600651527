import { useEffect } from "react";
export function Article({ title, description = "" }) {
  const parser = new DOMParser();
  const parsedTitle = parser.parseFromString(title, "text/html");
  let article = [];
  useEffect(() => {
    document.title = parsedTitle.body.innerHTML;
    const article = document.querySelector("article");
    description.forEach((desc) => {
      const parsedDesc = parser.parseFromString(desc, "text/html");
      const element = parsedDesc.body.firstChild;
      article.appendChild(element);
    });
  });

  return (
    <main className="container">
      <article>
        <h1>{parsedTitle.body.innerHTML}</h1>
        <hr style={{ width: "50%" }} />
        {article}
      </article>
    </main>
  );
}

export function ArticlePreviewCard({ title, link, description }) {
  return (
    <a
      href={link}
      style={{
        textDecoration: "none",
        display: "block",
        minWidth: "100%",
        height: "100%",
      }}
    >
      <div className="card" style={{ width: "100%", height: "100%" }}>
        <img
          style={{ height: "40%", maxHeight: "300px" }}
          src="./images/background.jpg"
          className="card-img-top"
          alt="..."
        />
        <div className="card-body" style={{ height: "60%" }}>
          <h5 className="card-title">{title}</h5>
          {description === undefined ? null : (
            <p className="card-text">{description}</p>
          )}
        </div>
      </div>
    </a>
  );
}

export function ArticlePreviewCard2({ title, link, description }) {
  return (
    <a
      href={link}
      style={{
        textDecoration: "none",
        display: "block",
        minWidth: "100%",
        height: "100%",
      }}
    >
      <div className="card flag">
        <img src="./images/background.jpg" className="card-img-top" alt="..." />
        <div className="card-body" style={{ height: "60%" }}>
          <h5 className="card-title">{title}</h5>
        </div>
      </div>
    </a>
  );
}
