import { Article } from "../../Components/Article";

export function Culture() {
  const title = "Diverse Ethnic Group";
  const description = [
    "<p>Ethiopia, often referred to as the <strong><i>Cradle of Humanity</i></strong> boasts a rich tapestry of cultures woven from its diverse ethnic groups. This landlocked nation in East Africa is home to over 80 distinct ethnicities, each with its own unique language, traditions, and customs.</p>",
    "<p>The four largest groups are the <strong>Oromo, Amhara, Somali, and Tigrayan</strong>. The Oromo people, making up over 34% of the population, reside primarily in the southern and western regions. The Amhara, at 28%, have historically held political power and their Amharic language serves as the nation's working language. Somalis, concentrated in the eastern lowlands, contribute significantly to Ethiopia's Islamic presence. Finally, the Tigrayans, inhabiting the northern highlands, possess a rich cultural heritage and their spoken language, Tigrinya, is closely related to Amharic.</p>",
    "<p>Beyond these prominent groups, Ethiopia embraces a multitude of smaller ethnicities. The <strong>Sidama</strong> people of the Sidama Region, the <strong> Gurage</strong> inhabiting the south-central highlands, and the <strong>Welayta</strong> in the southern region are just a few examples. These diverse communities speak languages belonging to the Cushitic and Omotic branches of the Afro-Asiatic language family.</p>",
    "<p>Religion also plays a significant role in Ethiopian culture. Christianity, introduced in the early 4th century, finds a strong following particularly among the Amhara and Tigrayan people. Islam, brought by traders in the 7th century, is more prevalent in the eastern lowlands and among the Somali people. Traditional African religions also persist, particularly among some southern ethnicities.</p>",
    "<p>This beautiful blend of cultures is evident in Ethiopia's vibrant festivals, music, and cuisine. From the intricate body art of the Karo people to the rhythmic dance of the Gurage, every group contributes to the nation's unique identity. Injera, a spongy flatbread, serves as a staple across the country, though variations and accompanying dishes differ by region.</p>",
    "<p>Ethiopia's ethnic diversity has not been without its challenges.Historical tensions and competition for resources have occasionally flared. However, the country's federal system, established in 1995, recognizes the rights of its various ethnicities and strives to promote unity in its motto: 'Unity, Democracy, and Prosperity.'</p>"
  ];

  return <Article title={title} description={description} />;
}
