import {v4 as uuidv4} from 'uuid';

export function Staff() {
  const listStyle = {
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    justifyContent: "center",
  };
  const style = {
    display: "flex",
    justifyContent: "flex-start",
    gap: "1rem",
    alignItems: "center",
    
    flexDirection: "column",
    overflow: "auto",
  }
  const contactList = [
    {
        name: "Mr. Berhane Gebre-Christos",
        text: "Head of Mission",
        phone: "010-65325258",
        email: "ethiochina@yahoo.com",
    },
    {
        name: "Mr. Genete Teshome Jirru",
        text: "Deputy Head of Mission",
        phone: "010-65325318",
        email: "geneteshome@gmail.com"
    },
    {
        name: "Mr. Yayne Siyoum Gebremariam",
        text: "Defense Attaché",
        phone: "010-65326381",
        email: "syayne@yahoo.com"
    },
    {
        name: "Mr. Asaye Alemayehu Dejjenie",
        text: "Political and Economic cooperation Affairs",
        phone: "010-65325318",
        email: "afework.shimelis@mfa.gov.et"
    },
    {
        name: "Mr. Negus Kebede Kassaw",
        text: "Investment and Commercial Affairs",
        phone: "010-65327804",
        email: "negusss12@yahoo.co.uk"
    },
    {
        name: "Mr. Getachew Uta Weju",
        text: "Trade and Tourism Affairs",
        phone: "010-65325318",
        email: "getachewuta@yahoo.com"
    },
    {
        name: "Mr. Masresha Endalew Berhe",
        text: "Trade and Tourism Affairs",
        phone: "010-65325318",
        email: "masresha.endalew@yahoo.com"
    },
    {
        name: "Mr. Teshome Shunde Hamito",
        text: "Public Diplomacy & Communication Affairs",
        phone: "010-65327905",
        email: "teshome.shunde@mfa.gov.et"
    },
    {
        name: "Mr. Takele Getaneh Woldemariam",
        text: "Administrative Affairs",
        phone: "010-65325318",
        email: "takele2009@yahoo.com"
    },
    {
        name: "Mr. Gebru Alemu Ageze",
        text: "Financial Affairs",
        phone: "010-65325534",
        email: "gebru.alemu@mfa.gov.et"
    },
    {
        name: "Mr. Daniel Zegeye Zeleke",
        text: "ICT Affairs",
        phone: "010-65325318",
        email: "daniel.zegeye@mfa.gov.et"
    },
    {
        name: "Mr. Teamrat Guangul Sibagadis",
        text: "Consular Affairs and Community Affairs",
        phone: "010-65321412",
        email: "steamrat@ymail.com"
    },
    {
        name: "Mr. Yahiya Idris Muhe",
        text: "Consular Affairs and Community Affairs",
        phone: "010-65321412",
        email: "hawsa2011@yahoo.com"
    },
    {
        name: "Mrs. Sinidu Geberetsadik Haylemariam",
        text: "Secretary to the Head of Mission",
        phone: "010-65325318",
        email: "sinidu.gebretsadik@mfa.gov.et"
    }
  ];
  let list = contactList.map((contact) => {
    return (
      <Card
        name={contact.name}
        text={contact.text}
        phone={contact.phone}
        email={contact.email}
        key={uuidv4()}
      />
    );
  });
  return (
    <main style={style}>
      <h2 style={{textAlign:"center", maxWidth:'800px', marginTop:'80px'}}>Contact information of the Ethiopian Embassy Diplomatic Staff in Beijing</h2>
      <div className="container">
        <div style={listStyle}>
          {list}
        </div>
      </div>
    </main>
  );
}

export function Card({ name, text, phone, email }) {
  const card = {
    minWidth: "250px",
    width: "15rem",
    maxWidth: "300px",
    display: "flex",
    alignItems: "center",
    filter: "drop-shadow(0px 4px 3px black)",
    padding: "0.5rem",
    position: "relative",
    flexShrink: "0",
    flexBase: "0",
  };
  const profileImg = {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
  };
  const contactInfo = {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    fontSize: "0.8rem",
    listStyle: "none",
    padding: "0",
  };
  return (
    <div className="card" style={card}>
      <img
        style={profileImg}
        src="/images/background.jpg"
        className="card-img-top"
        alt="..."
      />
      <div className="card-body">
        <h5 className="card-title">{name}</h5>
        <p className="card-text">{text}</p>
        <div>
          <ul style={contactInfo}>
            <li>Phone: {phone} </li>
            <li>Email: {email}</li>
          </ul>
        </div>
        <a href="#" className="btn btn-primary">
          View More
        </a>
      </div>
    </div>
  );
}
