import { Outlet } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
const Layout = () => {
  const service_list = [
    {
      text: "Visa Application Form",
      link: "https://www.ethiopiaemb.org.cn/amharic/Visa_application_form.pdf",
    },
    {
      text: "Passport Services",
      link: "/passport",
    },
    // {
    //   text: "Legalization",
    //   link: "/legalization",
    // },
    // {
    //   text: "Ethiopian Diaspora Portal",
    //   link: "/diaspora",
    // },
    // {
    //   text: "Other services",
    //   link: "/services",
    // },
  ];
  const consulates_list = [
    {
      text: "Guangzhou",
      link: "https://www.ethiopiaemb.org.cn/guangzhou.htm",
    },
    {
      text: "Shanghai",
      link: "https://www.ethiopiaemb.org.cn/shanghai.htm",
    },
    {
      text: "Chongqing",
      link: "https://www.ethiopiaemb.org.cn/chongqing.htm",
    },
  ];
  const culture_list = [
    {
      text: "Diverse Ethnic Group",
      link: "/culture",
    },
    // {
    //   text: "Language",
    //   link: "#",
    // },
    // {
    //   text: "Alphabet",
    //   link: "#",
    // },
    // {
    //   text: "Calendar",
    //   link: "#",
    // },
    // {
    //   text: "Religion",
    //   link: "#",
    // },
    // {
    //   text: "Festivals",
    //   link: "#",
    // },
    // {
    //   text: "Costumes",
    //   link: "#",
    // },
    // {
    //   text: "Cuisine",
    //   link: "#",
    // },
    // {
    //   text: "Arts & Crafts",
    //   link: "#",
    // },
  ];

  return (
    <>
      <div className="App">
        <nav className=" App-header navbar fixed-top">
          <div className="container-fluid">
            <a className="navbar-brand" href="/">
              <img className="logo" src="/images/logo_sm.png" alt="..." />
              <h1>The Embassy of F.D.R. Ethiopia in P.R. China</h1>
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="offcanvas offcanvas-end"
              tabIndex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                  Menu
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/">
                      Home
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Services
                    </span>
                    <ul className="dropdown-menu">
                      {service_list.map((service) => {
                        return (
                          <li key={uuidv4()}>
                            <a className="dropdown-item" href={service.link}>
                              {service.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Consulates
                    </span>
                    <ul className="dropdown-menu">
                      {consulates_list.map((consulate) => {
                        return (
                          <li key={uuidv4()}>
                            <a className="dropdown-item" href={consulate.link}>
                              {consulate.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/embassy-staff">
                      Staff Members
                    </a>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Culture
                    </span>
                    <ul className="dropdown-menu">
                      {culture_list.map((culture) => {
                        return (
                          <li key={uuidv4()}>
                            <a className="dropdown-item" href={culture.link}>
                              {culture.text}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Investment
                    </span>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="/investment/0">
                          General
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="/investment/1">
                          Investment Opportunities
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://www.ethiopiaemb.org.cn/invest_2_1.htm"
                        >
                          Specific Investment Projects
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://www.ethiopiaemb.org.cn/invest_3.htm"
                        >
                          Marketing Opportunities
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://www.ethiopiaemb.org.cn/invest_4.htm"
                        >
                          Government Support & Policy
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://www.ethiopiaemb.org.cn/invest_5.htm"
                        >
                          Contact Addresses
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <span
                      className="nav-link dropdown-toggle"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Investment Approval Procedure
                    </span>
                    <ul className="dropdown-menu">
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://www.ethiopiaemb.org.cn/investap1.htm"
                        >
                          One-Stop-Shop
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item"
                          href="https://www.ethiopiaemb.org.cn/investap2.htm"
                        >
                          Company Registration
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <Outlet />
        <footer>
          <div>
            <p style={{ margin: "0" }}>© 2024 Ethiopian Embassy</p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Layout;
