export function Modal() {
    return (
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">
                Contact Us
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>
                <strong>Address</strong>: No.3 Xiu Shui Nan Jie Jan Guo Men Wai,
                Beijing, 100600, P. R. China
              </p>
              <p>
                <strong>Tel</strong>: +861065325258
              </p>
              <p>
                <strong>Email</strong>: <a href="mailto:ethiochina@yahoo.com">ethiochina@yahoo.com</a>
              </p>
              <p>
                <strong>Fax</strong>: +861065325591
              </p>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    );
  }