import "./App.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import {Home} from "./pages/Home/Home";
import { Staff } from "./pages/Staff/Staff";
import { Investment } from "./pages/Investment/Investment";
import { Culture } from "./pages/Culture/Culture.";
import { Passport } from "./pages/Services/Passport";
import { About } from "./pages/About/About";
import { Article } from "./Components/Article";
function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="news" element={<Home/>} />
          <Route path="embassy-staff" element={<Staff/>} />
          <Route path="Passport" element={<Passport/>} />
          <Route path="tourism" element={<Home/>} />
          <Route path="culture" element={<Culture/>} />                    
          <Route path="consulates" element={<Home/>} />
          <Route path="about" element={<About/>} />
          <Route path="article/:id" element={<Article/>} />
          <Route path="investment/:id" element={<Investment/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;




